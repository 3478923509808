import { PageOptions } from '@graphcommerce/framer-next-pages'
import { RichText } from '@graphcommerce/graphcms-ui'
import { PageMeta, StoreConfigDocument } from '@graphcommerce/magento-store'
import { GetStaticProps, Separator } from '@graphcommerce/next-ui'
import { Trans } from '@lingui/react'
import { Box, Container, Link } from '@mui/material'
import React from 'react'
import { LayoutNavigation, LayoutNavigationProps } from '../components'
import { LayoutDocument, LayoutQuery } from '../components/Layout/Layout.gql'
import { DefaultPageDocument, DefaultPageQuery } from '../graphql/DefaultPage.gql'
import { graphqlSsrClient, graphqlSharedClient } from '../lib/graphql/graphqlSsrClient'

type Props = DefaultPageQuery & LayoutQuery
type GetPageStaticProps = GetStaticProps<LayoutNavigationProps, Props>

function RouteNotFoundPage(props: Props) {
  const { pageNotFound } = props

  const links = [
    <Link key={0} href='/' color='primary' underline='hover'>
      <Trans id='Store home' />
    </Link>,
    <Link key={1} href='/account' color='primary' underline='hover'>
      <Trans id='Account' />
    </Link>,
  ]

  return (
    <Container maxWidth='md' sx={(theme) => ({ textAlign: 'center', padding: theme.spacings.lg })}>
      <PageMeta title='Pagina niet gevonden' metaRobots={['noindex']} />
      {pageNotFound && (
        <RichText
          {...pageNotFound.content}
          sxRenderer={{
            paragraph: { marginBottom: 0 },
            'heading-one': { typography: 'h3' },
            image: (theme) => ({ padding: theme.spacings.sm }),
          }}
        />
      )}
      <Box mb={8}>
        {links.map((link, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={index}>
            {index > 0 && <Separator />}
            {link}
          </React.Fragment>
        ))}
      </Box>
    </Container>
  )
}

RouteNotFoundPage.pageOptions = {
  Layout: LayoutNavigation,
} as PageOptions

export default RouteNotFoundPage

export const getStaticProps: GetPageStaticProps = async ({ locale }) => {
  const client = graphqlSharedClient(locale)
  const staticClient = graphqlSsrClient(locale)
  const conf = client.query({ query: StoreConfigDocument })

  const page = staticClient.query({ query: DefaultPageDocument, variables: { url: `/` } })
  const layout = staticClient.query({ query: LayoutDocument, fetchPolicy: 'cache-first' })

  return {
    props: {
      ...(await page).data,
      ...(await layout).data,
      up: { href: '/', title: 'Home' },
      apolloState: await conf.then(() => client.cache.extract()),
    },
    revalidate: 60 * 20,
  }
}
